import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <p>RekhtaLabs is the Research and Development wing of  <a 
        href='https://rekhtafoundation.org'>
        Rekhta™ Foundation  
        </a>
         . ©All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
