import React from "react";
import "./Wishlist.css";
import Header from "./Header";
import Footer from "./Footer";

const Wishlist = () => {
  return (
    <>
      <Header pageTitle="Wishlist" />
      <div className="wishlist-container">
        <h1>How you can help us...</h1>
        <p>
          At RekhtaLabs, we are dedicated to advancing computational 
          linguistics and digital humanities, particularly for the Urdu language. 
          To achieve our ambitious goals, we require skilled people, powerful 
          hardware, and funding support. If you’re interested in contributing 
          in any way, here’s what we need:
        </p>

        <section className="wishlist-section">
          <h2>People</h2>
          <p>
            Our work benefits immensely from <strong>experts willing to 
            contribute their time</strong> for quality improvement, feedback, 
            and strategic direction. We welcome:
          </p>
          <ul>
            <li>
              <strong>AI developers</strong> who can contribute to our 
              open-source projects, model training, and AI-based tools
            </li>
            <li>
              <strong>NLP experts and academicians</strong> for collaborting on projects that have shared goals
            </li>
            <li>
              <strong>Language specialists</strong> for proofreading, OCR validation, 
              and transliteration accuracy, particularly for historical texts
            </li>
            <li>
              <strong>Activists and Linguists</strong> interested in advising on 
              <i> Language Preservation and Promotion</i>, advancing <i> Digital Humanities</i>,
              guiding us in <i>Feature Development</i> and <i> Content Structuring</i>
            </li>

          </ul>
        </section>

        <section className="wishlist-section">
          <h2>Computer Hardware </h2>
          <p>Processing large datasets and training AI models require high-end computing power. 
            Our hardware must be optimized for deep learning, NLP, and model fine-tuning to handle tasks efficiently.
            Following is a list of hardware we require to broaden our scope of work.
          </p>

          {/* Wishlist Table Image */}
          <div className="wishlist-image-container">
            <img 
              src="/wishlist.png" 
              alt="RekhtaLabs Hardware Wishlist" 
              className="wishlist-image"
            />
          </div>

          {/* Total Cost */}
          <p className="wishlist-total">
            Total Cost: <strong>~ ₹26.5Lacs</strong>
          </p>

          <p>
            Projects like 
            <a href="/projects/semantic-search" className="wishlist-link">Semantic Search</a>, 
            <a href="/projects/proof-reading-agent" className="wishlist-link">AI Proofreading Agent</a>, 
            <a href="/projects/transliteration" className="wishlist-link">Transliteration System</a>, 

            <a href="/projects/book-search" className="wishlist-link">OCR based search</a>, 
            and 
            <a href="/projects/islaah" className="wishlist-link">Islaah</a>&nbsp;
            rely on high-memory, multi-GPU systems to process millions of historical texts. 
            This setup will drastically reduce training time and enable real-time AI applications in linguistics.
            You can view the complete hardware wishlist and contribute via our  
            <a href="https://www.amazon.in/hz/wishlist/ls/1MCCGEYD1D9YG" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="wishlist-link">Amazon Wishlist</a>.
          </p>

        </section>




        <section className="wishlist-section">
            <h2>Funding</h2>
            <p>
                <strong>Rekhta Foundation</strong> is committed to making Urdu language 
                and literature more accessible through advanced AI and NLP research. 
                Your support enables us to:
            </p>
            <ul>
                <li>
                Recruit <strong>language experts</strong> to refine our OCR, 
                transliteration, and search systems
                </li>
                <li>
                Acquire <strong>high-end computing hardware</strong> to train 
                large-scale models efficiently
                </li>
                <li>
                Manage <strong>cloud computing expenses</strong>, ensuring that 
                our AI models keep improving
                </li>
            </ul>
            <p>
                Every contribution helps us push the boundaries of computational 
                linguistics. If you’d like to support our work, please consider donating 
                through our official page below. For inquiries or collaborations, feel 
                free to reach out at <a href="mailto:labs@rekhta.org">labs@rekhta.org</a>.
            </p>
            <a href="https://rekhtafoundation.org/donate" 
                target="_blank" 
                rel="noopener noreferrer"
                className="donate-button">
                Donate to Rekhta Foundation
            </a>
            </section>


      </div>

      <Footer />
    </>
  );
};

export default Wishlist;
