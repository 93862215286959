import React from 'react';
import './HomePage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import projects from '../Projects';

function HomePage() {
  return (
    <>
      <div className="home-page">
        <Header pageTitle="" />

        <main>
          {/* Hero Section */}
          <section className="hero">
            <p className="hero-title">
              <strong>
              Advancing the digital future of Indic languages through AI and NLP
              </strong>
            </p>
            <p>
              {/* <i> */}
                From poetic structures to linguistic research, we develop cutting-edge tools that enhance text analysis, search, and language preservation.
              {/* </i> */}
            </p>
            <Link to="/demos" className="cta-button">Explore Our Demos</Link>

            
          </section>

          <section className="whats-new-container">
  		      <div className="whats-new">
    			    <a href="/games/radeef-riddle" target='_blank'>🎉 New: Try the Radeef Riddle Game! 🎭</a>
  		      </div>
	        </section>


          {/* Research Areas */}
          <section className="research main-sections">
            <div className="section-title">What We Do</div>
            <p>
              We are committed to solving key challenges in computational linguistics for Urdu, Hindi, and other Indic languages.
              Our focus is on:
            </p>
            <ul>
              <li><strong>Natural Language Processing (NLP):</strong> Tokenization, POS tagging, diacritic restoration, and grammar correction</li>
              <li><strong>AI-powered Text Search:</strong> Advanced search algorithms for retrieving historical and poetic texts</li>
              <li><strong>Poetic Structure Analysis:</strong> Metrical analysis, pattern recognition, and automatic scansion</li>
              <li><strong>Speech and Text Convergence:</strong> AI-driven speech-to-text and text-to-speech models tailored for Urdu</li>
              <li><strong>Digital Archives and Preservation:</strong> Using technology to restore and make historical texts accessible</li>
            </ul>
          </section>

          {/* Projects Section */}
          <section className="projects-home main-sections">
            <div className="section-title">Our Projects</div>
            <p>
              We are actively developing AI and computational tools for Urdu NLP. Here are some of our current projects:
            </p>
            <div className="projects-grid-home">
              {projects.slice(0, 3).map(project => (
                <div key={project.id} className="project-card-home">
                  <h3>{project.title}</h3>
                  <p>{project.shortDescription}</p>
                  <Link to={project.link} className="view-project-link">Learn More →</Link>
                </div>
              ))}
            </div>
            <Link to="/projects" className="projects-cta-button">View All Projects</Link>
          </section>
          {/* Archive & Resources */}
          <section className="archive-resources main-sections">
            <div className="section-title">Research and Collaborations</div>
            <p>
              We are documenting, digitizing, and developing linguistic resources to facilitate research in NLP 
              and computational linguistics for low-resource Indic languages.
              Access our collection of research papers, learning material, and language datasets (coming soon) 
              in the <Link to="/archive">Archive</Link>.
              Rekhta Foundation also engages in <Link to="/collaborations">Collaborations</Link> with academia, other language related institutions 
              and independent language researchers through RekhtaLabs.
            </p>
          </section>


          {/* Wishlist Mention */}
          {/* <section className="home-wishlist main-sections">
            <p className="wishlist-cta">
              Join us in shaping the future of Urdu AI. <br></br>
              Check out our <Link to="/wishlist">Wishlist</Link> to see how you can help!<br></br>
              Reach out at labs@rekhta.org
            </p>
          </section> */}

        </main>

      </div>
      <Footer />
    </>
  );
}

export default HomePage;
