import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './Header.css';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LogoutLink from './LogoutLink';

const Header = ({ pageTitle }) => {
    const { auth, username } = useContext(AuthContext);
    const location = useLocation();
    const isHomePage = location.pathname === "/";

    return (
        <header className="header">
            <div className="header-left">
                <Link to="/" className="logo">
                    <img src="https://rekhta.pc.cdn.bitgravity.com/content/images/favico.png" alt="RekhtaLabs Logo" />
                </Link>
            </div>

            <div className="header-center">
                {isHomePage ? (
                    <span className="fixed-title not-clickable">RekhtaLabs</span>
                ) : (
                    <Link to="/" className="fixed-title">RekhtaLabs</Link>
                )}
                <div className="header-page-title">{pageTitle}</div>
            </div>

            <div className="header-right">
                {auth ? (
                    <>
                        <span>{username}</span>
                        <LogoutLink />
                    </>
                ) : (
                    <Link to="/login">Login</Link>
                )}
            </div>
        </header>
    );
}

Header.propTypes = {
    pageTitle: PropTypes.string.isRequired,
};

export default Header;
