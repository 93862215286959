const projects = [
  {
    id: 1,
    title: 'Semantic Search for Urdu Poetry',
    link: '/projects/semantic-search',
    image: "/semantic-search.png",
    status: "In Progress",
    shortDescription: "An AI-powered search engine for Urdu poetry.",
    longDescription: "Utilizing AI and vector-based search to retrieve semantically relevant couplets, overcoming OCR errors and transliteration issues.",
    fullDescription: `
      <h3>Lead : Ishant Manderwal (RekhtaLabs)</h3>
      <p>This project leverages a advanced Natural Language Processing (NLP) techniques to enhance the search experience for Urdu poetry.</p>
      <h3>Key Features</h3>
      <ul>
        <li>Utilizes a modern LLM-based architecture to enable semantic search</li>
        <li>Works on Rekhta's extensive shayari database</li>
        <li>Utilizes modern LLMs to embed and understand Shayaris deeply</li>
        <li>Break language barriers by enabling multilingual search capabilities</li>
      </ul>
      <p>The system is currently being fine-tuned to better capture nuances of classical and contemporary poetry.</p>
    `
  },
  {
    id: 2,
    title: 'Proofreading Agent for Urdu Texts',
    link: '/projects/proof-reading-agent',
    image: "/proof-reading-agent.png",
    status: "Starting Soon",
    shortDescription: "AI-assisted correction of Urdu texts.",
    longDescription: "Developing an AI model to assist in proofreading Urdu texts, correcting common OCR errors, diacritic mistakes, and grammatical inconsistencies.",
    fullDescription: `
      <h2>About the Proofreading Agent</h2>
      <p>The goal of this project is to automate and enhance the proofreading process for digitized Urdu texts.</p>
      <h3>Key Features</h3>
      <ul>
        <li>Identifies and corrects OCR mistakes commonly found in Urdu texts.</li>
        <li>Restores missing diacritics and improves grammatical accuracy.</li>
        <li>Uses AI models fine-tuned on historical and contemporary Urdu literature.</li>
      </ul>
      <p>Currently in the research phase, this project aims to streamline the proofreading process for large-scale digitization efforts.</p>
    `
  },
  {
    id: 3,
    title: 'Fuzzy Search for Digitized Books',
    link: '/projects/book-search',
    image: "/book-search.png",
    status: "In Progress",
    shortDescription: "Search historical Urdu books with OCR-tolerant fuzzy matching.",
    longDescription: "A robust search tool for scanned books, allowing users to find phrases even with OCR errors and linguistic variations.",
    fullDescription: `
      <h2>About Fuzzy Search for Books</h2>
      <p>This project builds an OCR-tolerant search engine for Urdu books, enabling readers to find content even if the digitized text contains errors.</p>
      <h3>Key Features</h3>
      <ul>
        <li>Uses fuzzy matching to identify words with OCR distortions.</li>
        <li>Indexes large historical archives for fast retrieval.</li>
        <li>Supports multiple Urdu script variations.</li>
      </ul>
      <p>Our goal is to enhance access to Urdu literary heritage through intelligent search technology.</p>
    `
  }
  ,
  {
    id: 5,
    title: 'Islaah',
    link: '/projects/islaah',
    image: "/islaah.png", // Update image when available
    status: "On Hold",
    shortDescription: "AI-assisted refinement of Urdu poetry.",
    longDescription: "A tool that analyzes poetic meter (Bahr) in Urdu couplets and suggests corrections to improve their structure.",
    fullDescription: `
      <h2>About Islaah</h2>
      <p>Islaah is a computational tool designed to assist poets in refining their couplets by analyzing and suggesting improvements in metrical structure (Bahr).</p>
      <h3>Key Features</h3>
      <ul>
        <li>Automatically detects metrical inconsistencies in Urdu couplets.</li>
        <li>Provides structured feedback and correction suggestions.</li>
        <li>Supports multiple poetic forms and metrical patterns.</li>
        <li>Helps beginners understand and improve their poetry through guided suggestions.</li>
      </ul>
      <p>The tool is needs to be improved to enhance its accuracy and provide real-time feedback on poetic composition.</p>
    `
  }
  ,
  {
    id: 4,
    title: 'Poetic Meter Analysis (Taqtii\')',
    link: '/projects/taqtii',
    image: "/taqtii.png",
    status: "Completed",
    shortDescription: "Automated metrical analysis of Urdu ghazals.",
    longDescription: "A tool that identifies the metrical pattern (Bahr) of Urdu poetry, aiding students and poets in understanding poetic structures.",
    fullDescription: `
      <h2>About Taqti' Tool</h2>
      <p>This project explores the rhythmic patterns of Urdu poetry, automatically analyzing meter using computational linguistics techniques.</p>
      <h3>Key Features</h3>
      <ul>
        <li>Detects metrical patterns (Bahr) in Urdu poetry.</li>
        <li>Provides insights into classical poetic structures.</li>
        <li>Supports multiple poetic forms and meters.</li>
      </ul>
      <p>The tool has been widely adopted by poetry enthusiasts and students.</p>
    `
  }
,
  {
    id: 6,
    title: 'Transliteration Systems for Urdu-Devanagari-Roman',
    link: '/projects/transliteration',
    image: "/transliteration.png",
    status: "In Progress",
    shortDescription: "Accurate and context aware Urdu/Devanagari/Roman transliteration.",
    longDescription: "Developing a machine-assisted system to mainly transliterate Urdu texts into Devanagari while preserving linguistic nuances.",
    fullDescription: `
      <h2>About Urdu-Devanagari Transliteration</h2>
      <p>This project focuses on building an accurate Urdu-to-Devanagari transliteration system that retains phonetic accuracy and literary structure.</p>
      <h3>Key Features</h3>
      <ul>
        <li>Disambiguation of words which are ambiguous due to missing diacritics in Urdu, like <b>اس=is/us? میں=me.n/mai.n?</b> etc.</li>
        <li>Handles complex script variations and sound mappings.</li>
        <li>Supports transliteration of poetry and prose.</li>
        <li>Balances rule-based and AI-driven approaches.</li>
      </ul>
      <p>This is particularly hard for poetry, as the LLMs still struggle to understand nearby context 
      for low resource languages and poetry has flexible grammar and may invoke implicit contexts.</p>
      <p>This is a core building block, which is needed improve other tasks (like TTS, translation etc).</p>
    `
  },
  {
    id: 7,
    title: 'Meer Sahab in Love',
    link: '/projects/meer-sahab-in-love',
    image: "/meer-love.png",
    status: "Concept Phase",
    shortDescription: "A poetic journey through the love life of Meer Taqi Meer.",
    longDescription: "A curated book that explores the life and emotions of Meer Taqi Meer through translations and structured narrative.",
    fullDescription: `
      <h3>Lead: Akash Arsh (Rekhta Editorial)</h3>
      <p>This book is a creative exploration of Meer Taqi Meer's love poetry, structured to reflect the different phases of a lover’s journey.</p>
      <h3>Key Features</h3>
      <ul>
        <li>Curated selections of Meer's poetry with thematic progression.</li>
        <li>Translations and transliterations for accessibility.</li>
        <li>Illustrated design to enhance the reading experience.</li>
      </ul>
      <p>The project is in its early conceptual stage, with plans for future publication.</p>
    `
  },
    {
      id: 8,
      title: 'Collaborative Filtering-Based Ghazal Recommendation System',
      link: '/projects/recommendation-system',
      image: "/recommendation.png",
      status: "Maintenance",
      shortDescription: "Personalized Urdu poetry recommendations using collaborative filtering.",
      longDescription: "A recommendation system that suggests poetry based on user interactions and similarity scores.",
      fullDescription: `
        <h2>About the Recommendation System</h2>
        <p>This project focuses on building a <b>collaborative filtering-based recommendation engine</b> that personalizes poetry suggestions based on user behavior.</p>
        <h3>Key Features</h3>
        <ul>
          <li>Analyzes user interactions with poetry collections.</li>
          <li>Uses matrix factorization techniques (ALS/SVD) for personalized recommendations.</li>
          <li>Incorporates popularity-based and content-based filtering for hybrid recommendations.</li>
          <li>Supports real-time user engagement feedback to refine suggestions.</li>
        </ul>
        <p>The system is being integrated with Rekhta’s poetry platform to enhance user experience and content discovery.</p>
      `
    },
    {
      id: 9,
      title: 'CartoChronicles: Poets Across Time and Space',
      link: '/projects/cartochronicles',
      image: "/cartochronicles.png",
      status: "Completed",
      shortDescription: "A visualization of poets and their influence across history.",
      longDescription: "An interactive historical and geographical mapping of poets to showcase their literary contributions and cultural impact.",
      fullDescription: `
        <h2>About CartoChronicles</h2>
        <p>CartoChronicles is an interactive <b>visualization tool</b> that maps poets across time and geography, providing insights into their literary influence and historical significance.</p>
        <h3>Key Features</h3>
        <ul>
          <li>Interactive map showcasing poets by era and location.</li>
          <li>Connections between poets and their influences across time.</li>
          <li>Detailed biographies and sample works linked to each poet.</li>
          <li>Integration with Rekhta’s poetry database for cross-referencing.</li>
        </ul>
        <p>The demo is coming soon.</p>
      `
    }
  ];
  
  export default projects;
  
