import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SherExplanationsChat.css";
import Header from "./Header";
import Footer from "./Footer";
import SherExplanationsSidebar from "./SherExplanationsSidebar"; // Import Sidebar

function SherExplanationsChat() {
  const [couplet, setCouplet] = useState("");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(() => localStorage.getItem("selectedLanguage") || "english");
  const [sher, setSher] = useState("");
  const [showImprovementInput, setShowImprovementInput] = useState(false);
  const [improvementHint, setImprovementHint] = useState("");

  const fetchExplanation = async (inputSher) => {
    setIsLoading(true);
    setSher("");
    setResponse(null);
    try {
      const result = await axios.post("/api/rl/tashriih/get_sher_explanation", { couplet: inputSher, language });
      setResponse(result.data);
      setSher(inputSher); // ✅ Display the selected sher
    } catch (error) {
      console.error("Error fetching explanation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchExplanation(couplet); // ✅ Works for manual input
  };

  const handleImprovementSubmit = async () => {
    const postData = { couplet, language, improvementHint };

    try {
      setIsLoading(true);
      setSher("");
      const result = await axios.post("/api/rl/tashriih/get_sher_explanation", postData);
      setResponse(result.data);
      setShowImprovementInput(false);
      setImprovementHint("");
    } catch (error) {
      console.error("Error posting improvement hint:", error);
    } finally {
      setIsLoading(false);
      setSher(couplet);
    }
  };

  useEffect(() => {
    localStorage.setItem("selectedLanguage", language);
  }, [language]);

  return (
    <div className="page-wrapper">
      <Header pageTitle="Tashriih"/>

      <div className="overall-wrapper">
        <div className="content-wrapper">
          <div className="form-container"> {/* ✅ Ensures the form is on top */}
            <form onSubmit={handleSubmit}>
              <div className="select-container">
                <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                  <option value="urdu">Urdu</option>
                  <option value="hindi">Hindi</option>
                  <option value="english">English</option>
                </select>
              </div>
              <div className="textarea-container">
                <textarea value={couplet} onChange={(e) => setCouplet(e.target.value)} placeholder="Enter a couplet" rows="6" />
              </div>
              <div className="button-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>

          {isLoading && <div className="loader"></div>}

          {response && (
            <div className="response-wrapper">
              <h2 className="sher-text">{sher}</h2> {/* ✅ Displaying correct sher */}

              <h3>Glossary</h3>
              <p>{response.glossary.map((entry, index) => <li key={index}>{entry}</li>)}</p>

              <h3>Tags</h3>
              <p>{response.tags.join(", ")}</p>

              <h3>Tashriih</h3>
              <p>{response.explanation}</p>

              <h3>English Transcreation</h3>
              <p>{response.english_transcreation}</p>

              <button onClick={() => setShowImprovementInput(!showImprovementInput)}>Improve Tashriih</button>
              {showImprovementInput && (
                <div>
                  <input type="text" value={improvementHint} onChange={(e) => setImprovementHint(e.target.value)} placeholder="Enter improvement hint" />
                  <button onClick={handleImprovementSubmit}>Submit</button>
                </div>
              )}
            </div>
          )}

          <SherExplanationsSidebar onSelectSher={fetchExplanation} /> {/* ✅ Clicking a saved couplet behaves like user input */}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SherExplanationsChat;
