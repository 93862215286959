import React from 'react';
import { Link } from 'react-router-dom';
import projects from '../Projects';
import './ProjectsPage.css';
import Footer from './Footer';
import Header from './Header';


const ProjectsPage = () => {
    return (
      <>
        <Header pageTitle="Projects" />
        <div className="projects-container">
          {projects.map(project => (
            <div key={project.id} className="project-card">
              {/* <img src={project.image} alt={project.title} /> */}
              <h2>{project.title}</h2>
              <span>STATUS:    </span> 
              <span className={`project-status status-${project.status.toLowerCase().replace(" ", "-")}`}>
                {project.status}
              </span>
              <p>{project.shortDescription}</p>
              <Link to={project.link} className="view-project">View Project →</Link>
            </div>
          ))}
        </div>
        <Footer />
      </>
    );
  };
  
  export default ProjectsPage;