import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SherExplanationsSidebar.css";

const SherExplanationsSidebar = ({ onSelectSher }) => {
  const [savedShers, setSavedShers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchSavedShers = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
        const response = await axios.get(`/api/rl/tashriih/get_saved_shers?page=${page}&limit=10`);
        // const response = await axios.get(`http://10.60.10.9:5020/api/rl/tashriih/get_saved_shers?page=${page}&limit=10`);
      if (response.data.success) {
        setSavedShers((prev) => [...prev, ...response.data.data]);
        setPage((prev) => prev + 1);
        setHasMore(response.data.data.length > 0);
      }
    } catch (error) {
      console.error("Error fetching saved shers:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSavedShers();
  }, []);

  return (
    <div className="sher-sidebar">
      <h2>📜 Saved Couplets</h2>
      <ul>
        {savedShers.map((sher, index) => (
          <li key={index} onClick={() => onSelectSher(sher.sher)}> {/* ✅ Sends selected sher to API */}
            <p className="sher-text">{sher.sher}</p>
          </li>
        ))}
      </ul>
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default SherExplanationsSidebar;
