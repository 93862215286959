import React from 'react';
import { useParams  , Link} from 'react-router-dom';
import projects from '../Projects';
import './ProjectDetail.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

const ProjectDetail = () => {
  const { projectId } = useParams();
  const project = projects.find(p => p.link === `/projects/${projectId}`);

  if (!project) {
    return (
      <>
        <Header pageTitle="Project Not Found" />
        <div className="project-detail-container error-message">
          <h2>Project not found.</h2>
          <Link to="/projects" className="back-link">← Back to Projects</Link>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header pageTitle='Project Details' />
      <div className="project-detail-container">
        <h1>{project.title}</h1>
        <div className={`project-status ${project.status.toLowerCase().replace(" ", "-")}`}>
            STATUS: {project.status}
        </div>
        {/* <img src={project.image} alt={project.title} className="project-image" /> */}
        <div className="project-description" dangerouslySetInnerHTML={{ __html: project.fullDescription }} />
        
        {/* Back to Projects Link */}
        <div className="back-to-projects">
          <Link to="/projects" className="back-link">← Back to Projects</Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProjectDetail;