const projects = [
   {
     id: 2,
     title: 'Semantic search',
     link: '/demo/semantic-search',
     image: "/semantic.png",
     shortDescription: "Search Urdu Couplets by theme and meaning",
     longDescription: `This project aims to enable users to find content semantically too,  
     supplementing the current keyword based search. 
     The demo is a little bit slow to respond, so please be patient after entering the query. WORK IN PROGRESS.
     `
   }, 
    {

    id: 2,
    title: 'Ebook Search',
    link: '/demo/booksearch',
    image: "/booksearch.png",
    shortDescription: "Fuzzy search and indexing for scanned Urdu books.",
    longDescription: "A powerful OCR-based search tool that allows users to find phrases in digitized Urdu books, even with OCR errors",
  },
  
  
  {
    id: 1,
    title: 'Tashriih',
    link: '/tashriih',
    image: "/tashriih.png",
    shortDescription: "AI generated explanation of couplets",
    longDescription: "Glossary, Tags, Explanation and an attempt at English transcreation of couplets using AI models finetuned on Rekhta data",
}, 
  // {
  //     id: 2,
  //     title: 'CartoChronicles',
  //     link: '/cartochronicles',
  //     image: '/CartoChronicles.png',
  //     shortDescription: "Poets across Time and Space.",
  //     longDescription: "A visualization tool that maps poets and their literary influence across time and geography.",
  // },
  // {
  //     id: 5,
  //     title: 'RLIT',
  //     link: '/rlit',
  //     image: "/it.png",
  //     shortDescription: "RekhtaLabs Input Tool for easy typing in Indic languages.",
  //     longDescription: "A phonetic typing tool that helps users type in multiple Indic scripts without a dedicated keyboard.",
  // },
  // {
  //     id: 3,
  //     title: 'Advanced Search',
  //     link: 'https://rekhta.org/advance-search',
  //     image: "/as.png",
  //     shortDescription: "An advanced poetry search engine",
  //     longDescription: "A powerful search tool for exploring Rekhta's vast poetry collection using keywords, themes, and filters.",
  // },
  {
      id: 4,
      title: "Taqtii'",
      link: 'https://rekhta.org/taqti/tool',
      image: "/taqti.png",
      shortDescription: "An exploration into the rhythm of ghazals",
      longDescription: "This tool analyzes poetic meter (Bahr) in Urdu ghazals, helping poetry learners to understand structure",
  },
  
];

export default projects;
