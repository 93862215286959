import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import collaborators from "../collaborators";
import "./Collaborations.css";

const Collaborations = () => {
  return (
    <>
      <Header pageTitle="Partnerships & Collaborations" />

      <div className="collab-container">
        <p className="collab-description">
          We acknowledge the contributions of the following esteemed institutions and individuals who have played a crucial role in our projects.
        </p>
        <div className="collab-grid">
          {collaborators.map((collab, index) => (
            <div key={index} className="collab-card">
              
              {/* Logo + Name in the same line */}
              <div className="collab-header">
                {collab.logo && <img src={collab.logo} alt={collab.name} className="collab-logo" />}
                {collab.name && <h2 className="collab-name">{collab.name}</h2>}
              </div>

              {collab.contributors && collab.contributors.length > 0 && (
                <p className="collab-meta ppl">{collab.contributors}</p>
              )}
              {collab.project && <p className="collab-meta"><strong>Project:</strong> {collab.project}</p>}
              {collab.year && <p className="collab-meta"><strong>Duration:</strong> {collab.year}</p>}
              {collab.location && <p className="collab-meta"><strong>Location:</strong> {collab.location}</p>}
              {collab.category && collab.category.length > 0 && (
                <p className="collab-meta"><strong>Category:</strong> {collab.category.join(", ")}</p>
              )}
              {collab.description && <p className="collab-meta">{collab.description}</p>}

              {/* Links Section */}
              {collab.link && collab.link_title && (
                <div className="coll-link">
                  <strong>{collab.link_title}:</strong> 
                  <a href={collab.link} target="_blank" rel="noopener noreferrer" className="collab-link">
                    {collab.link}
                  </a>
                </div>
              )}
              {collab.demo_link && collab.demo_link_title && (
                <div className="coll-link">
                  <strong>{collab.demo_link_title}:</strong> 
                  <a href={collab.demo_link} target="_blank" rel="noopener noreferrer" className="collab-link">
                    {collab.demo_link}
                  </a>
                </div>
              )}
              {collab.link_3 && collab.link_3_title && (
                <div className="coll-link">
                  <strong>{collab.link_3_title}:</strong> 
                  <a href={collab.link_3} target="_blank" rel="noopener noreferrer" className="collab-link">
                    {collab.link_3}
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Collaborations;
