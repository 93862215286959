import React from 'react';
import './DemosPage.css';
import projects from '../DemosData';
import Header from './Header';
import Footer from './Footer';

function DemosPage() {
    return (
        <div className="demos-page">
            <Header title="Demos" pageTitle="Demos" />
            
            <main className="demos-content">
                {projects.map((project, index) => (
                    <div key={project.id} className={`demo-card ${index % 2 === 0 ? 'left' : 'right'}`}>
                        <div className="demo-card-content">
                            <div className="demo-text">
                                <h2 className="demo-title">{project.title}</h2>
                                <p className="demo-short-desc">{project.shortDescription}</p>
                                <p className="demo-long-desc">{project.longDescription}</p>
                                <a href={project.link} className="demo-cta">Check it out</a>
                            </div>
                            <div className="demo-image-container">
                                <img src={project.image} alt={project.title} className="demo-image" />
                            </div>
                        </div>
                    </div>
                ))}
            </main>

            <Footer />
        </div>
    );
}

export default DemosPage;
