const collaborators = [
    {
      name: "Vision Lab, IIT Delhi",
      contributors: "with Prof. Chetan Arora, Prof. Arjun Ghosh",
      project: "Lipikar OCR, Book Search",
      description: "Vision Lab IITD has developed and provided the Lipikar OCR tool,\
        that works for 22 languages.\
        We are providing feedback for the tool for our cases and using it for \
        enabling ebook search for Urdu books on our platforms. \
       We plan to scale the use for enabling efficient Urdu book search and text extraction. \
       The tool has significantly enhanced our ability to process \
       historical and literary Urdu texts.\
        Demos can be seen at the links below.",
      year: "2024 - Present",
      category: ["OCR", "AI for Text Processing"],
      location: "IIT Delhi, India",
      link: "https://vision-iitd.github.io/",
      link_title:"Vision Lab Link",
      demo_link:"http://rekhtalabs.org/demo/booksearch",
      demo_link_title:"Book Search Demo",
      link_3:"https://lipikar.cse.iitd.ac.in/",
      link_3_title:"Lipikar-IITD",
      logo: "https://lipikar.cse.iitd.ac.in/static/images/iitdelhi-removebg-preview.png"
    },
    {
        name: "Thothica",
        contributors: "with Adnan Abbasi, Ashay Dwivedi",
        project: "Tashrih, Semantic Search",
        description: "Thothica, an AI research group, collaborated with us on enhancing NLP techniques for Urdu text analysis. \
        Their expertise in custom model development and low-resource language processing helped improve our work on \
        Tashrih and Semantic Search. They provided critical insights into deep-learning-based text understanding, \
        which aided in refining meaning extraction from poetic texts and optimizing search accuracy for classical Urdu literature.",
        year: "September 2023 - March 2024",
        category: ["Semantic Search", "NLP for Low-Resource Languages","Custom models"],
        location: "Delhi",
        link: "https://thothica.com",
        link_title: "Thothica",
        logo: "thothica.png" 
      },
    {
      name: "Individual Collaborator",
      contributors: "Hemendra Srivastava",
      project: "Search, Advanced Search, Semantic Search, Recommendations",
      description: "Hemendra, has guided us on our ML related prjects. An IITB-CSE 2011 graduate and NLP enthusiast\
      with interest in Indic languages, he is currently with Google.",
      year: "2022 - Present",
    },    {
      name: "Individual Collaborator",
      contributors: "Behjat Riyaz",
      project: "Urdu Text Disambiguation",
      description: "Ms Behjat Riyaz, then a computer science graduate sutdent at 'University Sains Malasia', interned with us,\
      and trained a model that achieved 85% accuracy in diambiguating Urdu words in case of izaafat - a form of compounding in Urdu \
      , which is ambiguous because of missing diacritics in Urdu.",
      year: "March 2023 - September 2023",
    },


  ];

  
  export default collaborators;
  